import Icon from '@/components/global/icon/Icon'
import { Card, CardContent } from '@/components/ui/card'
import TrackingCaseDetails from '@/pages/dashboard/case_details/components/tracking-link/component/TrackingCaseDetails'
import React from 'react'

type Props = {
    linkUrl: string
    orderDetails: any
}

export default function TrackingLink({ linkUrl, orderDetails }: Props) {

  return (
    <>
      <Card
        className={`mx-auto mb-5 mt-2 border-[1px] border-slate-700 overflow-hidden border-none bg-transparent font-sans text-black`}
      > 
        <div className='flex items-center justify-center bg-zinc-100'>
          <div className='text-2xl'>
          <Icon icon='streamline:ambulance' color="black" />
          </div>
          <div className={`bg-zinc-100 p-[1rem] text-center font-semibold text-sm`}>
            {'Ambulance Details'}
          </div>
        </div>
        {(orderDetails?.overallStatus !== 'completed' && linkUrl) ? <div className=' text-center'>
          <CardContent className='h-[90vh] max-h-[80vh] overflow-y-scroll p-0 '>
            <iframe
              className=' -mb-10 -mt-3 h-full w-full'
              src={`${linkUrl}-embedded`}
            />
          </CardContent>
        </div> : <div>
          <TrackingCaseDetails orderDetails={orderDetails} />
        </div>}
      </Card>
    </>
  )
}
