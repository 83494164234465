import { Icon as Iconify } from '@iconify/react'

interface IconProps {
  icon: string
  color: string
}

const Icon = ({ icon, color }: IconProps) => {
  return <Iconify icon={icon} color={color} />
}

export default Icon
